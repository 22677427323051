import { gettext } from "django-i18n";
import reverse from "django-reverse";
import { noop } from "lodash";

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Form from "_common/components/form";
import { Formik, Field } from "_common/components/form/Formik";
import Error from "_common/components/form/error";
import { renderTextField } from "_common/components/form/Field";
import { combineValidators, required, email } from "_common/validations";

import { forgotPassword } from "../actions";

/**
 * Reset your own password.
 */
const ForgotPasswordForm = ({ dispatch, error, history, initialValues }) => {
  const onSubmit = (values) =>
    dispatch(forgotPassword.submit(values)).then(() => {
      history.push(reverse("account_login"));
    });

  const renderCancel = (cancelButton) => (
    <Link to={reverse("account_login")}>{cancelButton}</Link>
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form
          title={gettext("Forgot Password")}
          submitLabel={gettext("Send")}
          cancelLabel={gettext("Cancel")}
          onSubmit={handleSubmit}
          onCancel={noop}
          renderCancel={renderCancel}
        >
          <p>
            {gettext(
              "Password reset instructions will be sent to your registered e-mail address."
            )}
          </p>

          <Field
            name="email"
            component={renderTextField}
            validate={combineValidators([required, email])}
            floatingLabelText={gettext("Email")}
            autoFocus
          />

          <Error>{error}</Error>
        </Form>
      )}
    </Formik>
  );
};

ForgotPasswordForm.defaultProps = {
  initialValues: {
    email: undefined,
  },
};

export default connect()(ForgotPasswordForm);
