import React from "react";

import { gettext } from "django-i18n";
import reverse from "django-reverse";
import { noop } from "lodash";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Form from "_common/components/form";
import { Formik, Field } from "_common/components/form/Formik";
import Error from "_common/components/form/error";
import { renderTextField } from "_common/components/form/Field";
import { required } from "_common/validations";

/**
 * Log into the application. Also used to display other account related forms.
 */
const LoginForm = ({
  error,
  errorMessages,
  initialValues,
  loginEmailReplacementLabel,
  loginMessage,
  loginPasswordReplacementLabel,
  loginForgotPasswordButtonHidden,
}) => {
  const formRef = React.useRef();

  const onSubmit = () => formRef.current.submit();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form
          formRef={formRef}
          title={gettext("Login")}
          submitLabel={gettext("Login")}
          cancelLabel={gettext("Forgot Password")}
          onSubmit={handleSubmit}
          onCancel={loginForgotPasswordButtonHidden ? undefined : noop}
          renderCancel={(cancelButton) => (
            <Link to={reverse("account_login_forgot_password")}>
              {cancelButton}
            </Link>
          )}
          method="POST"
        >
          {loginMessage && (
            // eslint-disable-next-line react/no-danger
            <p dangerouslySetInnerHTML={{ __html: loginMessage }} />
          )}

          <Field
            name="username"
            component={renderTextField}
            validate={required}
            floatingLabelText={loginEmailReplacementLabel || gettext("Email")}
            autoFocus
            data-cy="login-username"
          />

          <Field
            name="password"
            component={renderTextField}
            type="password"
            autoComplete="new-password"
            floatingLabelText={
              loginPasswordReplacementLabel || gettext("Password")
            }
            data-cy="login-password"
          />

          <Error>{(errorMessages && errorMessages.__all__) || error}</Error>
        </Form>
      )}
    </Formik>
  );
};

LoginForm.defaultProps = {
  initialValues: {
    username: "",
    password: "",
  },
};

const getInitialValues = (state) => {
  const { user_name: username } = state.django.context;

  return {
    username,
  };
};

const mapStateToProps = (state) => ({
  loginMessage: state.django.UI_STRS.LOGIN_MESSAGE,
  loginEmailReplacementLabel:
    state.django.UI_STRS.LOGIN_EMAIL_REPLACEMENT_LABEL,
  loginPasswordReplacementLabel:
    state.django.UI_STRS.LOGIN_PASSWORD_REPLACEMENT_LABEL,
  loginForgotPasswordButtonHidden:
    state.django.UI_STRS.LOGIN_FORGOT_PASSWORD_BUTTON_HIDDEN,
  errorMessages: state.django.context.error_messages || {},
  initialValues: getInitialValues(state),
});

export default connect(mapStateToProps)(LoginForm);
