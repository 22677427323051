import { gettext } from "django-i18n";
import { createLogic } from "redux-logic";

import { makeToast } from "_common/components/toast/actions";
import { apiRequest } from "_common/logic";

import { forgotPassword } from "../actions";
import LoginService from "../services/LoginService";

export const forgotPasswordLogic = createLogic({
  type: forgotPassword,
  latest: true,

  async process({ action }, dispatch, done) {
    const response = await apiRequest({
      dispatch,
      action: forgotPassword,
      fn: () => LoginService.forgotPassword(action.payload),
      resultMap: () => ({ success: true }),
    });

    if (!response) {
      return done();
    }

    const toastMessage = gettext(
      "You requested to reset your password. Instructions to reset your password will be sent to your registered e-mail address."
    );
    dispatch(makeToast("success", toastMessage));

    done();
  },
});
