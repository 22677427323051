import reverse from "django-reverse";

import React from "react";
import { Route } from "react-router-dom";

import bootstrap from "_common/bootstrapper";

import Root from "./containers/Root";
import reducer from "./reducers";
import logics from "./logics";

const rootComponent = (
  <Route path={reverse("account_login")} component={Root} />
);

bootstrap({
  reducer,
  rootComponent,
  logics,
});
