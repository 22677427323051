import { gettext } from "django-i18n";
import reverse from "django-reverse";

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { BarePage } from "_common/components/page";

import LoginForm from "../components/LoginForm";
import ForgotPasswordForm from "../components/ForgotPasswordForm";

const Root = ({
  loginForgotPasswordButtonHidden,
  subTitleText,
  theming,
  toasts,
}) => {
  const logoPaddingTop = `${(260 - theming.images.login.height) / 2}px`;

  const defaultSubtitle = "";
  const oldDefaultSubtitle = "Software Security Requirements System";

  const redirect = () => <Redirect to={reverse("account_login")} />;

  let subTitle = <div className="sde-login-subtitle">{defaultSubtitle}</div>;

  // We previously stored a default subtitle in default_strings.yaml
  // A customer may have it in their custom_strings.yaml
  // We hide the subtitle, if subTitleText matches the old default
  if (
    subTitleText &&
    subTitleText !== defaultSubtitle &&
    subTitleText !== oldDefaultSubtitle
  ) {
    subTitle = (
      <div
        className="sde-login-subtitle"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: subTitleText }}
      />
    );
  }

  return (
    <div className="sde-app">
      <BarePage toasts={toasts}>
        <div className="sde-login-logo" style={{ paddingTop: logoPaddingTop }}>
          <img {...theming.images.login} alt={gettext("SD Elements Logo")} />
          {subTitle}
        </div>

        <div className="sde-login-form">
          <Switch>
            <Route
              exact
              path={reverse("account_login")}
              component={LoginForm}
            />
            <Route
              path={reverse("account_login_forgot_password")}
              component={
                loginForgotPasswordButtonHidden ? redirect : ForgotPasswordForm
              }
            />
          </Switch>
        </div>
      </BarePage>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginForgotPasswordButtonHidden:
    state.django.UI_STRS.LOGIN_FORGOT_PASSWORD_BUTTON_HIDDEN,
  subTitleText: state.django.UI_STRS.LOGIN_SUBTITLE,
  theming: state.theming,
  toasts: state.toast.toasts,
});

export default connect(mapStateToProps)(Root);
