import BaseService from "_common/BaseService";

/**
 * Handles password resets.
 */
class LoginService extends BaseService {
  forgotPassword(body) {
    return this.axios.post("/accounts/password-reset/", body, {
      baseURL: "/",
      headers: { "X-Requested-With": "XMLHttpRequest" },
    });
  }
}

export default new LoginService();
